import { useContext } from "react";

import { Placement } from "api/requests/getBrandPlacement";
import MenuContext from "context/MenuContext";
import { Deal } from "custom-types/Deals";
import { Dispensary } from "custom-types/Dispensary";
import { MedicalIdCondition } from "custom-types/MedicalIdCondition";
import isDispensaryDualLicense from "utils/dispensary/isDispensaryDualLicense";

import ActiveDealsCarousel from "components/Dispensary/ActiveDealsCarousel";
import CategoryCarousel from "components/Dispensary/CategoryCarousel";
import LastUpdatedTime from "components/Dispensary/LastUpdatedTime";
import MedicalInfo from "components/Dispensary/MedicalInfo";
import PhenotypeCarousel from "components/Dispensary/PhenotypeCarousel";
import ProductRecTrays from "components/Dispensary/ProductRecTrays";
import PromotedBrandCarousel from "components/Dispensary/PromotedBrandCarousel";
import SearchBar from "components/Dispensary/SearchBar";
import ShopAllButton from "components/Dispensary/ShopAllButton";
import StaffPicksCarousel from "components/Dispensary/StaffPicksCarousel";
import RecentlyViewedMenuItems from "components/Shared/RecentlyViewedMenuItems";

const PaidTierMenu: React.FC<{
  brandPlacement: Placement | null;
  deals: Deal[];
  dispensary: Dispensary;
  recentlyViewedMenuItemsTreatment?: string;
}> = ({
  brandPlacement,
  deals,
  dispensary,
  recentlyViewedMenuItemsTreatment,
}) => {
  const {
    selectors: { categories },
  } = useContext(MenuContext);

  const isMedIdRequired =
    dispensary.orderMedIdCondition === MedicalIdCondition.Required;

  return (
    <div className="bg-white pt-xl">
      <div className="container">
        {isMedIdRequired && <MedicalInfo />}

        <SearchBar className="mb-sm mt-md" routeToMenu />

        <LastUpdatedTime className="mb-xl" />

        <div className="flex flex-col gap-xl">
          <CategoryCarousel />

          <PromotedBrandCarousel
            dispensary={dispensary}
            brandPlacement={brandPlacement}
          />

          {recentlyViewedMenuItemsTreatment !== "on" && (
            <RecentlyViewedMenuItems
              attributes={{ dispensarySlug: dispensary.slug }}
              isDispensaryPage={true}
              isDispensaryDualLicense={isDispensaryDualLicense(dispensary.tags)}
            />
          )}

          <ActiveDealsCarousel deals={deals} dealCardLinks />

          <StaffPicksCarousel
            dispensaryId={dispensary.id}
            dispensaryRetailType={dispensary.retailType}
            dispensarySlug={dispensary.slug}
          />

          <ProductRecTrays
            dispensaryRetailType={dispensary.retailType}
            dispensarySlug={dispensary.slug}
            dispensaryTags={dispensary.tags}
            categories={categories.slice(0, 1)}
          />

          <PhenotypeCarousel />

          <ProductRecTrays
            dispensaryRetailType={dispensary.retailType}
            dispensarySlug={dispensary.slug}
            dispensaryTags={dispensary.tags}
            categories={categories.slice(1)}
          />

          {recentlyViewedMenuItemsTreatment === "on" && (
            <RecentlyViewedMenuItems
              attributes={{ dispensarySlug: dispensary.slug }}
              isDispensaryPage={true}
              isDispensaryDualLicense={isDispensaryDualLicense(dispensary.tags)}
            />
          )}

          <ShopAllButton />
        </div>
      </div>
    </div>
  );
};
export default PaidTierMenu;
